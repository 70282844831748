:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.home-page__hero {
  background-color: rgba(33, 18, 94, 0.15);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 12rem 0; }
  .home-page__hero-text {
    overflow: hidden; }
  @media (max-width: 540px) {
    .home-page__hero {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 56px);
      justify-content: space-between;
      padding: 1rem; }
      .home-page__hero-text {
        margin-top: 5rem;
        width: 100%; }
      .home-page__hero-action {
        margin-bottom: 3rem;
        width: 100%; } }
  .home-page__hero__title {
    background-color: #fff;
    display: inline-block;
    transform: skew(45deg);
    padding: 1rem 3rem;
    margin-bottom: 0.5rem; }
    @media (max-width: 540px) {
      .home-page__hero__title {
        padding: 0.6rem 1rem;
        width: calc(100% - 4rem); } }
    .home-page__hero__title h1 {
      display: inline-block;
      transform: skew(-45deg);
      font-weight: 600;
      text-transform: uppercase;
      color: #21125e;
      font-size: 4rem;
      font-weight: 900; }
      @media (max-width: 540px) {
        .home-page__hero__title h1 {
          font-size: 1.125rem; } }
      @media (min-width: 540px) and (max-width: 992px) {
        .home-page__hero__title h1 {
          font-size: 48px; } }

.home-page__categories {
  margin-bottom: 2rem; }
  .home-page__categories h3 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1rem; }
  .home-page__categories__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem; }
    @media (min-width: 540px) {
      .home-page__categories__list {
        grid-template-columns: 1fr 1fr; }
        .home-page__categories__list > div:nth-child(3) {
          grid-column: 3 / 1; }
        .home-page__categories__list > div:nth-child(n + 4) {
          display: none; } }
    .home-page__categories__list a {
      text-decoration: none;
      color: #323232; }
      .home-page__categories__list a h3 {
        text-transform: uppercase;
        font-weight: 600; }
        .home-page__categories__list a h3:after {
          content: url("../../../images/carousel-arrow.svg");
          display: inline-block;
          transform: rotate(180deg);
          margin-left: 1rem; }
    .home-page__categories__list__image {
      width: 100%;
      height: 30rem;
      background-size: cover;
      background-position: center;
      margin-bottom: 1rem; }
      .home-page__categories__list__image--no-photo {
        background-color: #f1f5f5;
        background-repeat: no-repeat;
        background-size: contain; }
